import React, { useRef } from 'react';
import Lottie from 'lottie-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import newLottieJson from '../data/loader-new.json';
import colors from '../constants/colors';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  lottieContainer: {
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1501,
    position: 'fixed',
    backgroundColor: colors.blue,
  },
  lottieAnimate: {
    '& svg': {
      position: 'absolute',
      width: '65% !important',
      height: '65% !important',
      left: '0',
      top: '0',
      bottom: '0',
      right: '0',
      margin: 'auto',
      backgroundColor: colors.blue,
      zIndex: 1501,
    },
  },
  lottieMobileAnimate: {
    '& svg': {
      position: 'absolute',
      width: '200% !important',
      height: '104% !important',
      left: '-50%',
      right: '-45%',
      margin: 'auto',
      backgroundColor: colors.blue,
      zIndex: 1501,
    },
  },
}));

const LottieLoader = (props) => {
  const divRef = useRef(null);
  const lottieRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const removeLottie = () => {
    divRef.current.style.display = 'none';
    divRef.current.style.position = 'absolute';
    props.loaderComplete();
  };
  const enterFrame = (e) => {
    if (e.currentTime > 10 && lottieRef.current != null) {
      lottieRef.current = true;
      sessionStorage.setItem('lottieLoader', true);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.lottieContainer} ref={divRef}>
      <>
        {isMobile ? (
          <Lottie
            lottieRef={lottieRef}
            className={`${classes.lottieMobileAnimate}`}
            loop={false}
            onComplete={removeLottie}
            onEnterFrame={enterFrame}
            animationData={newLottieJson}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid meet',
            }}
          />
        ) : (
          <Lottie
            lottieRef={lottieRef}
            className={`${classes.lottieAnimate}`}
            loop={false}
            onComplete={removeLottie}
            onEnterFrame={enterFrame}
            animationData={newLottieJson}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid meet',
            }}
          />
        )}
      </>
    </div>
  );
};

export default LottieLoader;
