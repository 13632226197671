import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  makeStyles,
  IconButton,
  // Button,
  // Dialog,
  // DialogTitle,
  // DialogContent,
} from '@material-ui/core';
// import LiveEventForm from '../../section-description-innerhtml/LiveEventForm';
// import MenuPopOver from '../../header/menuPoOver';
import Fade from 'react-reveal/Fade';
import CustomLink from '../../custom-link';
import menuData from '../../../data/swipeable-drawer.json';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CloseIcon from '@material-ui/icons/Close';
import GmisLogo from '../../gmis-logo';
import { Link } from 'gatsby';
// import { getMobileOperatingSystem } from '../../../helpers/detect-device';

const axios = require('axios');
const API_URL = process.env.CMS_API_URL;

const useStyles = makeStyles((theme) => ({
  accordionGobal: {
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiAccordionDetails-root': {
      marginTop: '-15px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },

    '& .MuiListItem-gutters': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  customizedButton: {
    float: 'right',
  },
  socialIconHover: {
    color: theme.palette.text.primary,
    transition: 'color 0.2s ease-in-out',
    '&:hover': { color: theme.palette.primary.main },
  },
  padding0: {
    padding: 0,
  },
}));

const MobileMenu = ({ onClose }) => {
  const classes = useStyles();
  // const deviceSystem = getMobileOperatingSystem();
  const [isShowInitiative, setShowInitiative] = useState(false);
  // const [streamingURLIsActive, setStreamingURLIsActive] = useState();
  // const [openDialog, setOpenDialog] = useState(false);

  // const getStreaingURL = () => {
  //   try {
  //     axios
  //       .get(`${process.env.GATSBY_CMS_API_URL}/streamings/VIMEOURL`)
  //       .then((_res) => {
  //         if (_res.data.isActive) {
  //           setStreamingURLIsActive(_res.data.isActive);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getStreaingURL();
  // }, []);

  // const handleClickOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };
  // const [anchorUser, setAnchorUser] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorUser(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorUser(null);
  // };

  useEffect(() => {
    axios.get(`${API_URL}/initiatives`).then((response) => {
      if (response.status === 200) {
        const { data } = response;
        data.forEach((_initiative) => {
          if (_initiative.status === 'published') {
            setShowInitiative(true);
          }
        });
      }
    });
  }, []);

  return (
    <Box m={2}>
      <Grid container>
        <Grid item container>
          <Grid item xs={6}>
            <Link to='/'>
              <GmisLogo viewBox='0 0 90 50' width='90px' />
            </Link>
          </Grid>
          <Box clone position='absolute' right={0} pr={2}>
            <Grid item xs={6}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Grid>
        <Grid item container spacing={3}>
          {/* <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => navigate('https://cloudme02.infosalons.biz/reg/gmis21du/Registration')}>
              REGISTER
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' fullWidth onClick={() => navigate('/press/registration')}>
              REGISTER AS MEDIA
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' fullWidth onClick={() => navigate('/partner-with-us')}>
              PARTNER WITH US
            </Button>
          </Grid> */}

          {/* <Grid item xs={12}>
            {streamingURLIsActive && (
              <Button
                className='scaleAnimation'
                style={{
                  marginRight: '12px',
                }}
                onClick={handleClickOpenDialog}
                variant='contained'
                fullWidth
                color='primary'>
                Watch GMIS2021 Live Event
              </Button>
            )}
          </Grid> */}

          {menuData.mobileMenu.map(
            (_data, index) =>
              _data.heading === 'INITIATIVES' ? (
                isShowInitiative ? (
                  <Grid item xs={12}>
                    <Fade delay={index * 70}>
                      <Accordion elevation={0}>
                        <AccordionSummary
                          className={classes.padding0}
                          expandIcon={_data.subHeadings.length > 0 ? <ChevronRightIcon /> : null}>
                          {_data.link ? (
                            <CustomLink type={6} linkText={_data.heading} linkUrl={_data.link} />
                          ) : (
                            <Typography variant='h4' color='primary'>
                              {_data.heading}
                            </Typography>
                          )}
                        </AccordionSummary>
                        {_data.subHeadings.length > 0 ? (
                          <AccordionDetails>
                            <List>
                              {_data.subHeadings.map((_data, index) => (
                                <ListItem key={index}>
                                  <CustomLink type={4} linkText={_data.subheading} linkUrl={_data.link} />
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        ) : null}
                      </Accordion>
                    </Fade>
                  </Grid>
                ) : null
              ) : (
                <Grid item xs={12} className={classes.accordionGobal}>
                  <Fade delay={index * 70}>
                    <Accordion elevation={0}>
                      <AccordionSummary
                        className={classes.padding0}
                        expandIcon={_data.subHeadings.length > 0 ? <ChevronRightIcon /> : null}>
                        {_data.link ? (
                          <CustomLink type={6} linkText={_data.heading} linkUrl={_data.link} />
                        ) : (
                          <Typography variant='h4' color='primary'>
                            {_data.heading}
                          </Typography>
                        )}
                      </AccordionSummary>
                      {_data.subHeadings.length > 0 ? (
                        <AccordionDetails>
                          <List>
                            {_data.subHeadings.map((_data, index) => (
                              <ListItem key={index}>
                                <CustomLink type={4} linkText={_data.subheading} linkUrl={_data.link} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      ) : null}
                    </Accordion>
                  </Fade>
                </Grid>
              )
            /**** */
            // <Grid item xs={12}>
            //   <Fade delay={index * 70}>
            //     <Accordion elevation={0}>
            //       <AccordionSummary
            //         className={classes.padding0}
            //         expandIcon={_data.subHeadings.length > 0 ? <ChevronRightIcon /> : null}>
            //         {_data.link ? (
            //           <CustomLink type={6} linkText={_data.heading} linkUrl={_data.link} />
            //         ) : (
            //           <Typography variant='h4' color='primary'>
            //             {_data.heading}
            //           </Typography>
            //         )}
            //       </AccordionSummary>
            //       {_data.subHeadings.length > 0 ? (
            //         <AccordionDetails>
            //           <List>
            //             {_data.subHeadings.map((_data, index) => (
            //               <ListItem key={index}>
            //                 <CustomLink type={4} linkText={_data.subheading} linkUrl={_data.link} />
            //               </ListItem>
            //             ))}
            //           </List>
            //         </AccordionDetails>
            //       ) : null}
            //     </Accordion>
            //   </Fade>
            // </Grid>
            /**** */
          )}
        </Grid>
        <Grid item>
          <Box mt={5} mx='auto'>
            <Fade delay={800}>
              <Grid container spacing={3} wrap='nowrap'>
                <Grid item>
                  <a alt='Twitter' href='https://twitter.com/GMISummit' target='_blank' rel='noreferrer'>
                    <TwitterIcon className={classes.socialIconHover} />
                  </a>
                </Grid>
                <Grid item>
                  <a alt='Facebook' href='https://www.facebook.com/GMISummit' target='_blank' rel='noreferrer'>
                    <FacebookIcon className={classes.socialIconHover} />
                  </a>
                </Grid>
                <Grid item>
                  <a alt='Instagram' href='https://www.instagram.com/gmisummit' target='_blank' rel='noreferrer'>
                    <InstagramIcon className={classes.socialIconHover} />
                  </a>
                </Grid>
                <Grid item>
                  <a alt='Linkedin' href='https://www.linkedin.com/company/gmisummit' target='_blank' rel='noreferrer'>
                    <LinkedInIcon className={classes.socialIconHover} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    alt='Youtube'
                    href='https://www.youtube.com/channel/UCUupdONb4HHmL7dnne8vFKQ'
                    target='_blank'
                    rel='noreferrer'>
                    <YouTubeIcon className={classes.socialIconHover} />
                  </a>
                </Grid>
              </Grid>
            </Fade>
          </Box>
        </Grid>
      </Grid>
      {/* <Dialog onClose={handleCloseDialog} aria-labelledby='customized-dialog-title' open={openDialog}>
        <DialogTitle id='customized-dialog-title' onClose={handleCloseDialog}>
          <Box display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='center' flexGrow={1}>
              <Typography
                style={{
                  fontWeight: 'bold',
                }}
                variant='body1'>
                Register to Watch GMIS2021 Live Event
              </Typography>
            </Box>

            <Box>
              <IconButton color='primary' onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <LiveEventForm closeDialog={handleCloseDialog} />
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

export default MobileMenu;
