import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, Typography, Box, Grid, ListItem } from '@material-ui/core';
import CustomLink from '../../custom-link';
import Fade from 'react-reveal/Fade';
const CustomAccordionMenuItem = ({ link, url, title, index, classes, onClose }) => {
  const [isCorrectUrl, setIsCorrectUrl] = useState(url.includes(link));
  useEffect(() => {
    setIsCorrectUrl(url.includes(link));
  }, [url]);

  const closeOnSamePage = (isCorrectUrl) => {
    return isCorrectUrl;
  };

  return (
    <Grid item key={index} lg={12}>
      {/* <Fade delay={500 + index * 50}> */}
      <Accordion elevation={0} expanded={false}>
        <Box clone px={0}>
          {/* {closeOnSamePage(isCorrectUrl) ? (
            <Box onClick={onClose}>
              <Typography className={classes.onCloseTypography} variant='h6'>
                {title}
              </Typography>
            </Box>
          ) : ( */}
          <AccordionSummary className={classes.accordionRoot}>
            <CustomLink type={7} linkText={title} linkUrl={link} />
          </AccordionSummary>
          {/* )} */}
        </Box>
      </Accordion>
      {/* </Fade> */}
    </Grid>
  );
};

export const CustomAccordionDetailListItem = ({ link, url, title, index, classes, onClose, is2022Bold = false }) => {
  const [isCorrectUrl, setIsCorrectUrl] = useState(url.includes(link));
  useEffect(() => {
    setIsCorrectUrl(url.includes(link));
  }, [url]);

  const closeOnSamePage = (isCorrectUrl) => {
    return isCorrectUrl;
  };

  return closeOnSamePage(isCorrectUrl) ? (
    <Box onClick={onClose} pl={1.5}>
      <Typography variant='overline' className={`${classes.InnerOnCloseActive} ${classes.InnerOnCloseLink}`}>
        {title}
      </Typography>
    </Box>
  ) : (
    <ListItem
      className={is2022Bold && classes.twenty22}
      key={index}
      style={{
        paddingTop: '0px',
        paddingBottom: '0px',
      }}>
      <CustomLink type={4} linkText={title} linkUrl={link} />
    </ListItem>
  );
};
export default CustomAccordionMenuItem;
