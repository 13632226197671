import React from 'react';
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import MobileMenu from './mobile-menu';
import DesktopMenu from './desktop-menu';

const SwipeableDrawerContents = ({ onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth='xl'>
      <Grid container>
        <Grid item xs={12}>
          {isMobile ? <MobileMenu onClose={onClose} /> : <DesktopMenu onClose={onClose} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SwipeableDrawerContents;
