import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as MaterialLink, Typography, makeStyles, Box, Button } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
const useStyles = makeStyles((theme) => ({
  buttonHover: {
    '&:hover': {
      backgroundColor: '#a4c627',
      '& .MuiTypography-root': {
        color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  type1: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
  },
  type1Active: {
    fontWeight: 'bold',
    backgroundColor: fade(theme.palette.primary.main, 0.2),
  },
  type2: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  type2Active: {
    fontWeight: 'bold',
  },
  type3: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  type3Active: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  type4: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  type4Active: {
    color: theme.palette.text.primary,
    fontWeight: '900',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  type6: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  type6Active: {
    fontWeight: 'bold',
  },
  type8: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  },
  type8Active: {
    fontWeight: 'bold',
  },
}));

const CustomLink = ({
  isfiltersBiggerFont,
  linkUrl = '/',
  linkText = '',
  external,
  type = 1 || '1',
  callbackFn,
  isActive,
  download = false,
}) => {
  const classes = useStyles();

  switch (type) {
    case 1 || '1': {
      return external ? (
        <Box component='span' p={0.5}>
          <MaterialLink className={classes.type1} target='_blank' rel='noreferrer' href={linkUrl}>
            <Typography variant='overline'>{linkText}</Typography>
          </MaterialLink>
        </Box>
      ) : (
        <Box component='span' p={0.5}>
          <GatsbyLink activeClassName={classes.type1Active} to={linkUrl} className={classes.type1}>
            <Typography variant='overline'>{linkText}</Typography>
          </GatsbyLink>
        </Box>
      );
    }
    case 2 || '2': {
      return external ? (
        <MaterialLink className={classes.type2} target='_blank' rel='noreferrer' href={linkUrl}>
          <Typography variant='overline'>{linkText}</Typography>
        </MaterialLink>
      ) : (
        <GatsbyLink activeClassName={classes.type2Active} to={linkUrl} className={classes.type2}>
          <Typography variant='overline'>{linkText}</Typography>
        </GatsbyLink>
      );
    }
    case 3 || '3': {
      return external ? (
        <MaterialLink className={classes.type3} target='_blank' rel='noreferrer' href={linkUrl}>
          <Typography variant='overline'>{linkText}</Typography>
        </MaterialLink>
      ) : (
        <GatsbyLink activeClassName={classes.type3Active} to={linkUrl} className={classes.type3}>
          <Typography variant='overline'>{linkText}</Typography>
        </GatsbyLink>
      );
    }
    case 4 || '4': {
      return external ? (
        <MaterialLink className={classes.type4} target='_blank' rel='noreferrer' href={linkUrl}>
          <Typography variant='overline'>{linkText}</Typography>
        </MaterialLink>
      ) : (
        <GatsbyLink onClick={callbackFn} activeClassName={classes.type4Active} to={linkUrl} className={classes.type4}>
          <Typography variant='overline'>{linkText}</Typography>
        </GatsbyLink>
      );
    }
    case 5 || '5': {
      return (
        <Typography
          variant={isfiltersBiggerFont ? 'body1' : 'overline'}
          className={isActive && isActive === linkText ? [classes.type1, classes.type1Active].join(' ') : classes.type1}
          onClick={callbackFn}>
          {linkText}
        </Typography>
      );
    }
    case 6 || '6': {
      return external ? (
        <MaterialLink className={classes.type6} target='_blank' rel='noreferrer' href={linkUrl}>
          <Typography variant='h4'>{linkText}</Typography>
        </MaterialLink>
      ) : (
        <GatsbyLink onClick={callbackFn} activeClassName={classes.type6Active} to={linkUrl} className={classes.type6}>
          <Typography variant='h4'>{linkText}</Typography>
        </GatsbyLink>
      );
    }
    case 7 || '7': {
      return external ? (
        <MaterialLink className={classes.type6} target='_blank' rel='noreferrer' href={linkUrl}>
          <Typography variant='h6'>{linkText}</Typography>
        </MaterialLink>
      ) : (
        <GatsbyLink onClick={callbackFn} activeClassName={classes.type6Active} to={linkUrl} className={classes.type6}>
          <Typography variant='h6'>{linkText}</Typography>
        </GatsbyLink>
      );
    }
    case 8 || '8': {
      return external ? (
        <Button className={classes.buttonHover} variant='contained' color='primary'>
          <MaterialLink
            target='_blank'
            activeClassName={classes.type8Active}
            rel='noreferrer'
            href={linkUrl}
            className={classes.type8}>
            {linkText}
          </MaterialLink>
        </Button>
      ) : (
        <Button variant='contained' color='primary'>
          <GatsbyLink onClick={callbackFn} activeClassName={classes.type8Active} to={linkUrl} className={classes.type8}>
            {/* {download ? <a download> {linkText}</a> : { linkText }} */}
            {linkText}
          </GatsbyLink>
        </Button>
      );
      // return (
      //   <Button variant='contained' color='primary' onClick={() => navigate(linkUrl)}>
      //     {linkText}
      //   </Button>
      // );
    }
    default: {
      return external ? (
        <MaterialLink className={classes.type2} target='_blank' rel='noreferrer' href={linkUrl}>
          <Typography variant='overline'>{linkText}</Typography>
        </MaterialLink>
      ) : (
        <GatsbyLink activeClassName={classes.type2Active} to={linkUrl} className={classes.type2}>
          <Typography variant='overline'>{linkText}</Typography>
        </GatsbyLink>
      );
    }
  }
};

export default CustomLink;
