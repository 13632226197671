import { Grid, Divider, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React from 'react';
import CustomLink from '../custom-link';
import ImageContainer from '../image-container';

const useStyles = makeStyles((theme) => ({
  footerTextLinks: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  footerIconLinks: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  socialIconHover: {
    color: theme.palette.text.primary,
    transition: 'color 0.2s ease-in-out',
    '&:hover': { color: theme.palette.primary.main },
  },
  linksHover: {
    transition: 'color 0.2s ease-in-out',
    '&:hover': { color: theme.palette.primary.main },
  },
  socialIconWidth: {
    width: '0.8em',
    '&:MuiSvgIcon-root': {
      width: '0.8em !important',
    },
  },
}));
export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Box mt={15} mb={2}>
        <Divider />
        <Box clone mt={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9}>
              <Box display='flex' className={classes.footerTextLinks}>
                <Box pr={2} textAlign='center'>
                  <CustomLink linkUrl='/contact-us' linkText='Contact Us' type={3} />
                </Box>

                {/* <Box pr={2} textAlign='center'>
                  <CustomLink linkUrl='/registration' linkText='Register' type={3} />
                </Box> */}

                <Box display={{ xs: 'none', sm: 'block' }}>
                  <Box pr={2} textAlign='center'>
                    <CustomLink linkUrl='/privacy-policy' linkText='Privacy Policy' type={3} />
                  </Box>
                </Box>
                <Box display={{ xs: 'none', sm: 'block' }}>
                  <Box pr={2} textAlign='center'>
                    <CustomLink linkUrl='/terms-and-conditions' linkText='Terms & Conditions' type={3} />
                  </Box>
                </Box>
                <Box ml={3} mr={4} display={{ xs: 'none', sm: 'block' }}>
                  <Divider orientation='vertical' light />
                </Box>
                <Box
                  display={{ xs: 'none', sm: 'flex' }}
                  flexDirection='column'
                  justifyContent='center'
                  style={{
                    width: '180px',
                  }}>
                  <a target='_blank' href='https://webntech.ae/'>
                    <ImageContainer filename='powered-by' altText='powered-by' />
                  </a>
                </Box>
              </Box>
              <Box display={{ xs: 'flex', sm: 'none' }} className={classes.footerTextLinks}>
                <Box pr={2} textAlign='center'>
                  <CustomLink linkUrl='/privacy-policy' linkText='Privacy Policy' type={3} />
                </Box>
                <Box pr={2} textAlign='center'>
                  <CustomLink linkUrl='/terms-and-conditions' linkText='Terms & Conditions' type={3} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box display='flex' className={classes.footerIconLinks} pr={smBreakPoint ? 6 : 0}>
                <Box pr={1}>
                  <a alt='Twitter' href='https://twitter.com/GMISummit' target='_blank' rel='noreferrer'>
                    <TwitterIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                  </a>
                </Box>
                <Box pr={1}>
                  <a alt='Facebook' href='https://www.facebook.com/GMISummit' target='_blank' rel='noreferrer'>
                    <FacebookIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                  </a>
                </Box>
                <Box pr={1}>
                  <a alt='Instagram' href='https://www.instagram.com/gmisummit' target='_blank' rel='noreferrer'>
                    <InstagramIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                  </a>
                </Box>
                <Box>
                  <a alt='Linkedin' href='https://www.linkedin.com/company/gmisummit' target='_blank' rel='noreferrer'>
                    <LinkedInIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                  </a>
                </Box>
                <Box pl={1}>
                  <a
                    alt='Youtube'
                    href='https://www.youtube.com/channel/UCUupdONb4HHmL7dnne8vFKQ'
                    target='_blank'
                    rel='noreferrer'>
                    <YouTubeIcon className={classes.socialIconHover} />
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display={{ xs: 'block', sm: 'none' }}
                m='auto'
                mt={3}
                style={{
                  width: '200px',
                }}>
                <a target='_blank' href='https://webntech.ae/'>
                  <ImageContainer filename='powered-by' altText='powered-by' />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
