import React, { useState, useEffect } from 'react';
import Header from './header/header';
import CustomSwipeableDrawer from './swipeable-drawer-layout/swipeable-drawer';
import { Container, Fab, Box, Fade } from '@material-ui/core';
import Footer from './footer/footer';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import colors from '../constants/colors';
import ScrollTop from '../components/header/scroll-top';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LottieLoader from './lottie-loader';
import ParticlesBG from './particles';

export default function Layout({
  props,
  children,
  isRemoveTopMargin,
  isSelectOveride = false,
  currentURL = 'notHomePage',
  justLogo = false,
}) {
  const [openSidebar, setOpenSidebar] = useState(false);
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkTheme] = useState(true);
  const [particles, setParticles] = useState(false);
  const [removeParticles, setRemoveParticles] = useState(false);
  const [showLottie, setShowLottie] = useState(true);
  const [isLottieComplete, setIsLottieComplete] = useState(false);
  // const [isAgenda, setIsAgenda] = useState(false);

  // useEffect(() => {
  //   if (cookie.load('prefersDarkMode') === undefined) {
  //     setDarkTheme(prefersDarkMode);
  //   } else {
  //     setDarkTheme(cookie.load('prefersDarkMode', false));
  //   }
  // }, [prefersDarkMode]);

  // useEffect(() => {
  //   if (!showLottie || isLottieComplete) {
  //     document.getElementById('___gatsby').style.height = 'auto !important';
  //     document.getElementById('___gatsby').style.overflow = 'auto !important';
  //   }
  // }, []);

  const theme = React.useMemo(() => {
    let _theme = createMuiTheme({
      palette: {
        type: darkTheme ? 'dark' : 'light',
        background: {
          default: darkTheme ? colors.blue : colors.white,
          dark: colors.primary.dark,
          light: colors.white,
        },
        primary: {
          main: darkTheme ? colors.primary.dark : colors.primary.light,
          dark: colors.primary.dark,
          light: colors.primary.light,
        },
        secondary: {
          main: darkTheme ? colors.secondary.dark : colors.secondary.light,
          dark: colors.secondary.dark,
          light: colors.secondary.light,
        },
        error: {
          main: darkTheme ? colors.error.dark : colors.error.light,
          dark: colors.error.dark,
          light: colors.error.light,
        },
        warning: {
          main: darkTheme ? colors.warning.dark : colors.warning.light,
          dark: colors.warning.dark,
          light: colors.warning.light,
        },
        info: {
          main: darkTheme ? colors.info.dark : colors.info.light,
          dark: colors.info.dark,
          light: colors.info.light,
        },
        success: {
          main: darkTheme ? colors.success.dark : colors.success.light,
          dark: colors.success.dark,
          light: colors.success.light,
        },
        text: {
          primary: darkTheme ? colors.text.primary.dark : colors.text.primary.light,
          secondary: darkTheme ? colors.text.secondary.dark : colors.text.secondary.light,
          disabled: darkTheme ? colors.text.disabled.dark : colors.text.disabled.light,
        },
      },
      zIndex: {
        one: 1,
      },
      typography: {
        fontSize: 16,
        h1: {
          fontWeight: 400,
          fontSize: '3rem',
        },
      },
      overrides: {
        // MuiList: {
        //   root: {
        //     maxHeight: '300px',
        //   },
        // },
        MuiInput: {
          underline: {
            '&:before': {
              borderBottom: 0,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 0,
            },
          },
        },
        MuiFormHelperText: {
          root: {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
        MuiAutocomplete: {
          root: {
            padding: '0px',
          },
          input: {
            padding: '12px 8px 12px 8px !important',
          },
        },
        MuiInputBase: {
          root: {
            borderRadius: '5px',
            backgroundColor: darkTheme
              ? `${colors.white}${colors.transparency.input.secondary}`
              : // : `${colors.black}${colors.transparency.input.primary}`,
                `${colors.blue}${colors.transparency.input.primary}`,
            '&:hover': {
              backgroundColor: darkTheme
                ? `${colors.white}${colors.transparency.input.primary}`
                : // : `${colors.black}${colors.transparency.input.secondary}`,
                  `${colors.blue}${colors.transparency.input.secondary}`,
            },
          },
          input: {
            padding: '12px 8px 12px 8px',
          },
          inputMultiline: {
            padding: '12px 8px 0px 8px',
          },
        },
        MuiInputLabel: {
          formControl: {
            transform: 'translate(0, 30px) scale(1)',
          },
        },
        MuiSelect: {
          icon: {
            fill: !isSelectOveride ? colors.transparentColor.primary : null,
          },
        },
        MuiStepper: {
          root: {
            padding: '0px',
            // backgroundColor: darkTheme ? colors.black : colors.white,
            // backgroundColor: darkTheme ? colors.blue : colors.white,
            backgroundColor: colors.transparentColor.primary,
          },
        },

        MuiStep: {
          horizontal: {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
        },
        MuiStepConnector: {
          active: {
            '& .MuiStepConnector-line': {
              borderColor: darkTheme ? colors.primary.dark : colors.primary.light,
            },
          },
          completed: {
            '& .MuiStepConnector-line': {
              borderColor: darkTheme ? colors.primary.dark : colors.primary.light,
            },
          },
        },
        MuiAppBar: {
          colorDefault: {
            // backgroundColor: darkTheme ? colors.black : colors.white,
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },
        MuiButton: {
          containedPrimary: {
            color: darkTheme ? colors.primary.dark : colors.primary.light,
            border: '1px solid',
            borderColor: darkTheme ? colors.primary.dark : colors.primary.light,
            backgroundColor: darkTheme
              ? `${colors.primary.dark}${colors.transparency.button}`
              : `${colors.primary.light}${colors.transparency.button}`,
            '&:hover': {
              color: colors.white,
              backgroundColor: darkTheme ? `${colors.primary.dark}` : `${colors.primary.light}`,
            },
          },
        },
        MuiCardContent: {
          root: {
            // backgroundColor: darkTheme ? colors.black : colors.white,
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },
        MuiPaper: {
          root: {
            // backgroundColor: darkTheme ? colors.black : colors.white,
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },
        MuiAccordion: {
          root: {
            '&:before': {
              // backgroundColor: darkTheme ? colors.white : colors.black,
              backgroundColor: darkTheme ? colors.white : colors.blue,
              opacity: 0.05,
            },
          },
        },
        MuiAccordionSummary: {
          color: colors.text.secondary,
          expandIcon: {
            color: colors.text.secondary,
            fontSize: '40px',
            '&$expanded': {
              transform: 'rotate(90deg)',
            },
          },
        },
      },
    });
    return responsiveFontSizes(_theme);
  }, [darkTheme]);

  const scrollToAgenda = () => {
    if (window.location.href.includes('#agenda')) {
      var elmnt = document.getElementById('agenda');
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }
  };

  const scrollToWatchEvent = () => {
    if (window.location.href.includes('#watch-live-stream')) {
      var elmnt = document.getElementById('watch-live-stream');
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      scrollToAgenda();
      scrollToWatchEvent();
    }, 3000);
    if (sessionStorage.getItem('lottieLoader') === 'true') {
      setShowLottie(false);
      setParticles(false);
      const removeParticlesTimeout = setTimeout(() => {
        setRemoveParticles(true);
        clearTimeout(removeParticlesTimeout);
        // if (window.location.href.includes('#agenda')) {
        //   document.getElementById('agenda').scrollIntoView();
        // }
      }, 2000);
    }
    // if (window.location.href.includes('#agenda')) {
    //   setIsAgenda(true);
    // }
  }, []);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // const toggleTheme = (e) => {
  //   setDarkTheme(e.target.checked);
  //   cookie.save('prefersDarkMode', e.target.checked, { path: '/' });
  // };

  const onLoaderComplete = () => {
    setIsLottieComplete(true);
    setParticles(false);
    // if (isAgenda) document.getElementById('agenda').scrollIntoView();
    const removeParticlesTimeout = setTimeout(() => {
      setRemoveParticles(true);
      scrollToAgenda();
      scrollToWatchEvent();
      clearTimeout(removeParticlesTimeout);
    }, 2500);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {/* {showLottie === true && <LottieLoader loaderComplete={onLoaderComplete} />}
        {currentURL !== '/' ? (
          removeParticles ? null : !showLottie ? (
            <Box
              className={[
                'particals-layout-transition',
                !particles ? 'particals-layout-transition-animation' : null,
              ].join(' ')}>
              <ParticlesBG
                value={100}
                speed={1}
                class={['particles-layout-bg', !particles ? 'particles-layout-bg-animation' : null].join(' ')}
              />
            </Box>
          ) : null
        ) : null} */}

        {/* {!showLottie || isLottieComplete ? (
          <Fade timeout={1000} in={!showLottie || isLottieComplete ? (!particles ? true : false) : false}> */}
        <Box>
          {!particles ? <ParticlesBG value={70} speed={0.3} class='particles-bg' /> : null}
          <Container maxWidth='xl'>
            <Header justLogo={justLogo} onSidebarOpen={handleSidebarOpen} checked={darkTheme} />
            <CustomSwipeableDrawer open={openSidebar} onOpen={handleSidebarOpen} onClose={handleSidebarClose} />
            {isRemoveTopMargin ? children : <Box mt={10}>{children}</Box>}
            <Footer />
          </Container>
        </Box>
        {/* </Fade>
        ) : null} */}

        <ScrollTop {...props}>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </CssBaseline>
    </ThemeProvider>
  );
}
