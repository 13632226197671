import React, { useState, useEffect, useRef } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import SwipableDrawerContents from './swipeable-drawer-contents';
import { makeStyles, Box } from '@material-ui/core';
import ParticlesBG from '../../components/particles';
// import { Transition } from 'react-transition-group';

const useStyles = makeStyles({
  fullWidth: {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
  // openDrawer: {
  //   '& .MuiDrawer-paperAnchorRight': {
  //     transform: 'translate(100% , -100%)',
  //     transition: 'transform 0.3s ease-out',
  //   },
  // },
  // closeDrawer: {
  //   '& .MuiDrawer-paperAnchorRight': {
  //     transform: 'translate(100% , -100%) !important',
  //     transition: 'transform 0.3s ease-out',
  //   },
  // },
});

// const duration = 2000;
// const defaultStyle = {
//   transition: `all ${duration}ms ease-in-out`,
//   transform: 'translate(0%, -0%)',
// };
// const transitionStyles = {
//   entering: { transform: 'translate(0%, -0%)' },
//   entered: { transform: 'translate(0%, -0%)' },
//   exiting: { transform: 'translate(100%, -100%)' },
//   exited: { transform: 'translate(100%, -100%)' },
// };

export default function CustomSwipeableDrawer(props) {
  const drawerRef = useRef(null);
  const { open, onOpen, onClose } = props;
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const classes = useStyles();
  const [url, setUrl] = useState('');
  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <>
      {/* <Transition in={open} timeout={duration}>
        {(state) => {
          return (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}> */}
      <SwipeableDrawer
        ref={drawerRef}
        className={[classes.fullWidth, open && classes.openDrawer, !open && classes.closeDrawer].join(' ')}
        // className={classes.fullWidth}
        anchor='right'
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}>
        {/* {open && showParticles ? ( */}
        <SwipableDrawerContents open={open} onOpen={onOpen} onClose={onClose} url={url} />
        {/* ) : null} */}
        {/* particals-layout-transition */}
        <Box className={['particals-menu-transition', 'particals-menu-transition-animation'].join(' ')}>
          <ParticlesBG value={100} speed={1} class='particles-menu-bg' />
        </Box>
      </SwipeableDrawer>
      {/* </div>
          );
        }}
      </Transition> */}
    </>
  );
}
