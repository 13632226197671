import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Container,
  useScrollTrigger,
  useMediaQuery,
  Box,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // Typography,
} from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
// import LiveEventForm from '../section-description-innerhtml/LiveEventForm';
// import MenuPopOver from './menuPoOver';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import GmisLogo from '../gmis-logo';
import logo2022 from '../../images/svgs/GMIS_America_logo_transparent.png';
import { useLocation } from '@reach/router';
// import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  toolBar: {
    padding: 0,
  },
  toggleIcons: {
    padding: '2px',
    borderRadius: '50%',
  },
  lightIcon: {
    backgroundColor: 'white',
  },
  darkIcon: {
    backgroundColor: '#1d1b1b',
  },
  logoStyle: {
    width: '238px',
    height: '50px',
    paddingTop: theme.spacing(1),
  },
  anchorToolbar: {
    margin: 0,
    padding: 0,
    height: 0,
    minHeight: 0,
  },
  registerMenuMargin: {
    marginTop: '10px',
  },
  imageContainer: {
    width: '100px',
    height: '75px',
  },
  c4ir: {
    marginTop: '17px',
    width: '180px',
    height: '22px',
  },
  divider: {
    height: '50px',
  },
}));

const ElevationScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const theme = useTheme();
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));

  return React.cloneElement(children, {
    elevation: trigger ? 6 : 0,
    color: xsBreakPoint ? 'default' : trigger ? 'default' : 'transparent',
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header(props) {
  const location = useLocation();

  const classes = useStyles();
  const { onSidebarOpen } = props;
  const theme = useTheme();
  // const [streamingURLIsActive, setStreamingURLIsActive] = useState();
  // const [openDialog, setOpenDialog] = useState(false);

  // const getStreaingURL = () => {
  //   try {
  //     axios
  //       .get(`${process.env.GATSBY_CMS_API_URL}/streamings/VIMEOURL`)
  //       .then((_res) => {
  //         if (_res.data.isActive) {
  //           setStreamingURLIsActive(_res.data.isActive);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getStreaingURL();
  // }, []);

  // const handleClickOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };
  // const [anchorUser, setAnchorUser] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorUser(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorUser(null);
  // };
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <ElevationScroll {...props}>
        <AppBar position='fixed'>
          <Container maxWidth='xl'>
            <Toolbar className={classes.toolBar}>
              <Grid container justify='space-between' alignItems='center'>
                <Grid item>
                  <Box display='flex'>
                    <Box>
                      {/* {location.pathname.includes('2022') ? (
                        <Link to='/'>
                          <Box display={{ xs: 'none', sm: 'block' }}>
                            <img width='238px' src={logo2022} />
                          </Box>
                        </Link>
                      ) : ( */}
                      <Link to='/'>
                        <Box display={{ xs: 'none', sm: 'block' }}>
                          <GmisLogo width='238px' viewBox='0 0 238 50' />
                        </Box>

                        <Box display={{ xs: 'block', sm: 'none' }}>
                          <GmisLogo width='90px' viewBox='0 0 90 50' />
                        </Box>
                      </Link>
                      {/* )} */}
                    </Box>
                  </Box>
                </Grid>

                {!props.justLogo ? (
                  !xsBreakPoint ? (
                    <Grid item>
                      {/* //! hide this if we are on a page that has registration in it */}
                      {/* <MenuPopOver
                        anchorEl={anchorUser}
                        open={Boolean(anchorUser)}
                        onClose={handleClose}
                        link='https://event.crowdcompass.com/gmis'
                      />

                      <Button
                        style={{
                          marginRight: '12px',
                        }}
                        onClick={handleClick}
                        variant='contained'
                        color='primary'
                        aria-controls='download-GMIS2021'
                        aria-haspopup='true'>
                        DOWNLOAD GMIS2021 APP
                      </Button> */}

                      {/* {streamingURLIsActive && (
                        <Button
                          className='scaleAnimation'
                          style={{
                            marginRight: '12px',
                          }}
                          onClick={handleClickOpenDialog}
                          variant='contained'
                          color='primary'>
                          Watch GMIS2021 Live Event
                        </Button>
                      )} */}
                      {/* <a
                        style={{
                          textDecoration: 'none',
                        }}
                        href='/pdfs/gmis2021-genda.pdf'
                        download
                        rel='noreferrer'>
                        <Button
                          variant='contained'
                          color='primary'
                          style={{
                            marginRight: '12px',
                          }}>
                          Download #GMIS2021 Agenda
                        </Button>
                      </a> */}
                      {/* <Button
                      style={{
                        marginRight: '12px',
                      }}
                      variant='contained'
                      color='primary'
                      onClick={() => navigate(gmis2021Agenda)}>
                      Download #GMIS2021 Agenda
                    </Button> */}
                      {/* )} */}

                      {/* {!location.pathname.includes('press/registration') && (
                        <Button
                          style={{
                            marginRight: '12px',
                          }}
                          variant='contained'
                          color='primary'
                          onClick={() => navigate('/press/registration')}>
                          REGISTER AS MEDIA
                        </Button>
                      )} */}

                      {/* {!location.pathname.includes('registration') && (
                        <Button
                          style={{
                            marginRight: '12px',
                          }}
                          variant='contained'
                          color='primary'
                          onClick={() => navigate('https://cloudme02.infosalons.biz/reg/gmis21du/Registration')}>
                          REGISTER
                        </Button>
                      )} */}
                      {/* 
                      <Button
                        style={{
                          marginRight: '12px',
                        }}
                        variant='contained'
                        color='primary'
                        onClick={() => window.open('https://gmisamerica.cventevents.com/08Pme9')}>
                        Register for GMIS America
                      </Button> */}

                      <IconButton color='primary' onClick={onSidebarOpen}>
                        <Menu />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item>
                      {/* {!location.pathname.includes('registration') && (
                        <Box clone mr={1} my={1}>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => window.open('https://gmisamerica.cventevents.com/08Pme9')}>
                            Register for GMIS America
                          </Button>
                        </Box>
                      )} */}
                      {/* {!location.pathname.includes('partner-with-us') && (
                      <Box clone mr={1} my={1}>
                        <Button variant='contained' color='primary' onClick={() => navigate('/partner-with-us')}>
                          PARTNER WITH US
                        </Button>
                      </Box>
                    )} */}

                      {/* <Button
                        size='small'
                        style={{
                          fontSize: '12px',
                        }}
                        variant='contained'
                        color='primary'
                        onClick={() => window.open('https://gmisamerica.cventevents.com/08Pme9')}>
                        Register for GMIS America
                      </Button> */}

                      <IconButton color='primary' onClick={onSidebarOpen}>
                        <Menu />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </Grid>
            </Toolbar>
          </Container>
          {/* <Dialog onClose={handleCloseDialog} aria-labelledby='customized-dialog-title' open={openDialog}>
            <DialogTitle id='customized-dialog-title' onClose={handleCloseDialog}>
              <Box display='flex'>
                <Box display='flex' flexDirection='column' justifyContent='center' flexGrow={1}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                    }}
                    variant='body1'>
                    Register to Watch GMIS2021 Live Event
                  </Typography>
                </Box>

                <Box>
                  <IconButton color='primary' onClick={handleCloseDialog}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>

            <DialogContent>
              <LiveEventForm closeDialog={handleCloseDialog} />
            </DialogContent>
          </Dialog> */}
        </AppBar>
      </ElevationScroll>
      <Toolbar id='back-to-top-anchor' className={classes.anchorToolbar} />
    </>
  );
}
