import React, { useRef, useEffect } from 'react';
// import Particles from 'react-particles-js';
import Particles from 'react-tsparticles';
export default function ParticlesBG(props) {
  const particlesRef1 = useRef(null);
  const particlesRef2 = useRef(null);

  useEffect(() => {
    return function cleanup() {
      if (particlesRef2.current !== null) {
        particlesRef2.current.pause();
  
        // particlesRef.current.plugins.get('emitters').array[0].pause();
      }
    };
  });
  // function particlesInit(main) {

  //   // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  // }

  return (
    <Particles
      id={props.class}
      container={props.class === 'particles-bg' ? particlesRef1 : particlesRef2}
      className={props.class}
      // loaded={particlesLoaded}
      options={{
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 10,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 50,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: '#a4c627',
          },
          links: {
            color: '#a4c627',
            distance: 150,
            enable: true,
            opacity: 0.2,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: true,
            outMode: 'bounce',
            random: true,
            speed: props.speed,
            straight: false,
          },
          number: {
            density: {
              enable: true,
            },
            value: props.value,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 2,
          },
        },
        detectRetina: true,
      }}
    />
  );
}
