import React, { useState, useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  Box,
  Hidden,
  makeStyles,
  createStyles,
  Card,
  CardActionArea,
  Typography,
} from '@material-ui/core';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ImageContainer from '../../image-container';
import CustomLink from '../../custom-link';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) =>
  createStyles({
    media: {
      height: 300,
      zIndex: '10',
      position: 'relative',
    },
    cornerCardBorder: {
      border: `5px solid ${theme.palette.background.default} !important`,
      borderRadius: '3px',
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        // transition: 'all 0.5s !important',
        borderColor: `${theme.palette.primary.main} !important`,
        '&::before': {
          backgroundColor: theme.palette.background.default,
        },
        '&::after': {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    onCloseTypography: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);
const ImageAccordionMenu = ({ title, link, filename, defaultExpanded, url, onClose }) => {
  const classes = useStyles();
  const [isCorrectUrl, setIsCorrectUrl] = useState(url.includes(link));
  useEffect(() => {
    setIsCorrectUrl(url.includes(link));
  }, [url]);

  const closeOnSamePage = (isCorrectUrl, url, link) => {
    return (isCorrectUrl && link === url[url.length - 1]) || link[link.length - 1] === url[url.length - 1];
  };
  return (
    <>
      <Hidden smDown>
        <Card square={true} elevation={0} className='card'>
          <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
            <CardActionArea className='zIndex-1'>
              {closeOnSamePage(isCorrectUrl, url, link) ? (
                <Box className='clickBox' onClick={onClose}>
                  <ImageContainer filename={filename} altText='#GMIS2021' />
                </Box>
              ) : (
                <Link to={link}>
                  <ImageContainer filename={filename} altText='#GMIS2021' />
                </Link>
              )}
            </CardActionArea>
          </Box>
        </Card>
      </Hidden>
      <Box clone>
        {/* <Accordion elevation={0} expanded={false}> */}
        <Box clone px={0}>
          {closeOnSamePage(isCorrectUrl, url, link) ? (
            // <AccordionSummary >
            <Box onClick={onClose} mt={0.5}>
              <Typography className={classes.onCloseTypography} variant='h4'>
                {title}
              </Typography>
            </Box>
          ) : (
            // </AccordionSummary>
            // <AccordionSummary >
            <Box mt={1}>
              <CustomLink type={6} linkText={title} linkUrl={link} />
            </Box>
            // </AccordionSummary>
          )}
        </Box>
        {/* </Accordion> */}
      </Box>
    </>
  );
};
export default ImageAccordionMenu;
