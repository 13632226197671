import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql, useStaticQuery } from 'gatsby';
import * as _ from 'lodash';

const ImageContainer = ({ filename = 'default', altText = filename }) => {
  const { mobileImage, tabletImage, laptopImage, desktopImage } = useStaticQuery(graphql`
    query {
      mobileImage: allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
      tabletImage: allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
      laptopImage: allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
      desktopImage: allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `);

  const srcSet = [];
  const _mobileImage = _.filter(mobileImage.edges, (n) => n.node.name === filename);
  if (_mobileImage.length === 0) {
    filename = 'default';
    const _mobileImage = _.filter(mobileImage.edges, (n) => n.node.name === filename);
    srcSet.push(_.get(_mobileImage[0], 'node.childImageSharp.fluid'));

    const _tabletImage = _.filter(tabletImage.edges, (n) => n.node.name === filename);
    srcSet.push({ ..._.get(_tabletImage[0], 'node.childImageSharp.fluid'), media: `(min-width: 600px)` });

    const _laptopImage = _.filter(laptopImage.edges, (n) => n.node.name === filename);
    srcSet.push({ ..._.get(_laptopImage[0], 'node.childImageSharp.fluid'), media: `(min-width: 1000px)` });

    const _desktopImage = _.filter(desktopImage.edges, (n) => n.node.name === filename);
    srcSet.push({ ..._.get(_desktopImage[0], 'node.childImageSharp.fluid'), media: `(min-width: 1920px)` });
  } else {
    srcSet.push(_.get(_mobileImage[0], 'node.childImageSharp.fluid'));

    const _tabletImage = _.filter(tabletImage.edges, (n) => n.node.name === filename);
    srcSet.push({ ..._.get(_tabletImage[0], 'node.childImageSharp.fluid'), media: `(min-width: 600px)` });

    const _laptopImage = _.filter(laptopImage.edges, (n) => n.node.name === filename);
    srcSet.push({ ..._.get(_laptopImage[0], 'node.childImageSharp.fluid'), media: `(min-width: 1000px)` });

    const _desktopImage = _.filter(desktopImage.edges, (n) => n.node.name === filename);
    srcSet.push({ ..._.get(_desktopImage[0], 'node.childImageSharp.fluid'), media: `(min-width: 1920px)` });
  }

  return (
    <>
      <Img
        fluid={srcSet}
        alt={altText || _mobileImage[0].edges.node.name || '#GMIS2021'}
        objectPosition='50% 50%'
        objectFit='cover'
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
      {/* <p>image was here</p> */}
    </>
  );
};

export default ImageContainer;
