import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Grid,
  Divider,
  List,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import ImageContainer from '../../image-container';
// import Fade from 'react-reveal/Fade';
// import Zoom from 'react-reveal/Zoom';
import menuData from '../../../data/swipeable-drawer.json';
import './desktop-menu.scss';
// import CustomLink from '../../custom-link';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GmisLogo from '../../gmis-logo';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'gatsby';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ImageAccordionMenu from '../image-accordion-menu';
import CustomAccordionMenuItem from '../sub-menu/index';
import { CustomAccordionDetailListItem } from '../sub-menu/index';

const axios = require('axios');
const API_URL = process.env.CMS_API_URL;

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    // '& .MuiButtonBase-root': {
    padding: '0px !important',
    // },
  },
  accordionGobal: {
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiAccordionDetails-root': {
      marginTop: '-15px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  customizedButton: {
    float: 'right',
  },
  paddingNone: {
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
  },
  disabledAccordion: {
    '& .Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  socialIconHover: {
    color: theme.palette.text.primary,
    transition: 'color 0.2s ease-in-out',
    '&:hover': { color: theme.palette.primary.main },
  },
  socialIconWidth: {
    width: '0.8em',
    '&:MuiSvgIcon-root': {
      width: '0.8em !important',
    },
  },
  menuClose: {
    position: 'absolute',
    // top: theme.spacing(1),
    top: 0,
    right: theme.spacing(2),
    zIndex: 1,
  },
  logoandCloseGrid: {
    position: 'relative',
  },
  gridMargin: {
    marginRight: '-15px',
  },
  onCloseTypography: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  InnerOnCloseLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  InnerOnCloseActive: {
    color: theme.palette.primary.main,
  },
  twenty22: {
    '& a span': {
      fontWeight: '900',
    },
  },
  hideOverFlow: {
    // overflow: 'hidden',
  },
  fullHeightContainer: {
    height: 'calc(100vh - 120px)',
  },
  cornerCardBorder: {
    // height: '100%',
    // padding: theme.spacing(1),
    border: '2px solid',
    borderRadius: '2px',
    backgroundColor: theme.palette.background.default,
    borderColor: `${theme.palette.primary.main} !important`,
    '&::before': {
      backgroundColor: theme.palette.background.default,
    },
    '&::after': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const DesktopMenu = ({ onClose }) => {
  const classes = useStyles();

  const [isShowInitiative, setShowInitiative] = useState(false);
  const [url, setUrl] = useState('');
  useEffect(() => {
    setUrl(window.location.href);

    axios.get(`${API_URL}/initiatives`).then((response) => {
      if (response.status === 200) {
        const { data } = response;

        data.forEach((_initiative) => {
          if (_initiative.status === 'published') {
            setShowInitiative(true);
          }
        });
      }
    });
  }, []);
  return (
    <Box className={classes.hideOverFlow}>
      <Box clone my={2}>
        <Grid container>
          <Grid item xs={12} className={classes.logoandCloseGrid}>
            <Box mb={0} mt={-1.5} pl={1}>
              <Link to='/'>
                <GmisLogo viewBox='0 0 238 50' width='238px' />
              </Link>
            </Box>
            <Box className={classes.menuClose}>
              <IconButton onClick={onClose} className={classes.customizedButton}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container className={classes.fullHeightContainer}>
        <Grid item lg={7}>
          {/* <img src='../../../images/the-menu.jpg' /> */}
          <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
            <Box position='relative' zIndex={22}>
              <ImageContainer filename='the-menu' altText='menu' />
            </Box>
          </Box>

          <Grid container spacing={2} justify='space-between'>
            {menuData.desktopMenu.mainMenu.map((_data, index) => (
              <Grid item key={index} lg={6}>
                {/* <Zoom delay={index * 30}> */}
                <ImageAccordionMenu
                  title={_data.heading}
                  link={_data.link}
                  filename={_data.filename}
                  defaultExpanded={_data.defaultExpanded}
                  url={url}
                  onClose={onClose}
                />
                {/* </Zoom> */}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Divider style={{ margin: 'auto' }} orientation='vertical' />
        </Grid>
        <Grid className={classes.accordionGobal} item container lg={3} style={{ alignContent: 'space-between' }}>
          <Grid item xs={12}>
            <Grid container>
              {menuData.desktopMenu.subMenu.map(
                (_data, index) =>
                  _data.heading === 'INITIATIVES' ? (
                    isShowInitiative ? (
                      _data.subHeadings.length > 0 ? (
                        <Grid item key={index} lg={12}>
                          {/* <Fade delay={500 + index * 30}> */}
                          <Accordion elevation={0}>
                            <Box clone px={0}>
                              <AccordionSummary
                                className={classes.accordionRoot}
                                expandIcon={_data.subHeadings.length > 0 ? <ChevronRightIcon /> : null}>
                                <Typography variant='h6' color='primary'>
                                  {_data.heading}
                                </Typography>
                              </AccordionSummary>
                            </Box>
                            {_data.subHeadings.length > 0 ? (
                              <AccordionDetails>
                                <List>
                                  {_data.subHeadings.map((_data, index) => (
                                    <CustomAccordionDetailListItem
                                      link={_data.link}
                                      title={_data.subheading}
                                      url={url}
                                      classes={classes}
                                      onClose={onClose}
                                    />
                                  ))}
                                </List>
                              </AccordionDetails>
                            ) : null}
                          </Accordion>
                          {/* </Fade> */}
                        </Grid>
                      ) : (
                        <CustomAccordionMenuItem
                          link={_data.link}
                          title={_data.heading}
                          url={url}
                          index={index}
                          classes={classes}
                          onClose={onClose}
                        />
                      )
                    ) : null
                  ) : _data.subHeadings.length > 0 ? (
                    <Grid item key={index} lg={12}>
                      {/* <Fade delay={500 + index * 30}> */}
                      <Accordion elevation={0}>
                        <Box clone px={0}>
                          <AccordionSummary
                            className={classes.accordionRoot}
                            expandIcon={_data.subHeadings.length > 0 ? <ChevronRightIcon /> : null}>
                            <Typography variant='h6' color='primary'>
                              {_data.heading}
                            </Typography>
                          </AccordionSummary>
                        </Box>
                        {_data.subHeadings.length > 0 ? (
                          <AccordionDetails>
                            <List>
                              {_data.subHeadings.map((_data, index) => {
                                return (
                                  <CustomAccordionDetailListItem
                                    is2022Bold={_data.subheading === '2022-PITTSBURGH'}
                                    link={_data.link}
                                    title={_data.subheading}
                                    url={url}
                                    classes={classes}
                                    onClose={onClose}
                                  />
                                );
                              })}
                            </List>
                          </AccordionDetails>
                        ) : null}
                      </Accordion>
                      {/* </Fade> */}
                    </Grid>
                  ) : (
                    <CustomAccordionMenuItem
                      link={_data.link}
                      title={_data.heading}
                      url={url}
                      index={index}
                      classes={classes}
                      onClose={onClose}
                    />
                  )
                /******** */
                // _data.subHeadings.length > 0 ? (
                //   <Grid item key={index} lg={12}>
                //     {/* <Fade delay={500 + index * 30}> */}
                //     <Accordion elevation={0}>
                //       <Box clone px={0}>
                //         <AccordionSummary
                //           className={classes.accordionRoot}
                //           expandIcon={_data.subHeadings.length > 0 ? <ChevronRightIcon /> : null}>
                //           <Typography variant='h6' color='primary'>
                //             {_data.heading}
                //           </Typography>
                //         </AccordionSummary>
                //       </Box>
                //       {_data.subHeadings.length > 0 ? (
                //         <AccordionDetails>
                //           <List>
                //             {_data.subHeadings.map((_data, index) => (
                //               <CustomAccordionDetailListItem
                //                 link={_data.link}
                //                 title={_data.subheading}
                //                 url={url}
                //                 classes={classes}
                //                 onClose={onClose}
                //               />
                //             ))}
                //           </List>
                //         </AccordionDetails>
                //       ) : null}
                //     </Accordion>
                //     {/* </Fade> */}
                //   </Grid>
                // ) : (
                //   <CustomAccordionMenuItem
                //     link={_data.link}
                //     title={_data.heading}
                //     url={url}
                //     index={index}
                //     classes={classes}
                //     onClose={onClose}
                //   />
                // )
                /******** */
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {/* <CustomAccordionMenuItem
                  link='/registration'
                  title='REGISTER'
                  index='idxcd'
                  classes={classes}
                  onClose={onClose}
                  url={url}
                /> */}
              {/* <Grid item lg={12}>
                  <Accordion elevation={0} expanded={false}>
                    <Box clone px={0}>
                      <Fade delay={1000}>
                        <AccordionSummary className={classes.accordionRoot}>
                          <CustomLink type={7} linkText='REGISTER' linkUrl='/registration' />
                        </AccordionSummary>
                      </Fade>
                    </Box>
                  </Accordion>
                </Grid> */}
              <Grid item lg={12}>
                <Grid container className='theIcons' lg={12}>
                  {menuData.desktopMenu.socialLinks.map((_data, index) => (
                    <Grid item xs={2} key={index} className={classes.gridMargin}>
                      {/* <Fade delay={1000 + index * 40}> */}
                      {_data.platform === 'Twitter' ? (
                        <a alt='Twitter' href='https://twitter.com/GMISummit' target='_blank' rel='noreferrer'>
                          <TwitterIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                        </a>
                      ) : _data.platform === 'Facebook' ? (
                        <a alt='Facebook' href='https://www.facebook.com/GMISummit' target='_blank' rel='noreferrer'>
                          <FacebookIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                        </a>
                      ) : _data.platform === 'Instagram' ? (
                        <a alt='Instagram' href='https://www.instagram.com/gmisummit' target='_blank' rel='noreferrer'>
                          <InstagramIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                        </a>
                      ) : _data.platform === 'Linkedin' ? (
                        <a
                          alt='Linkedin'
                          href='https://www.linkedin.com/company/gmisummit'
                          target='_blank'
                          rel='noreferrer'>
                          <LinkedInIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                        </a>
                      ) : _data.platform === 'Youtube' ? (
                        <a
                          alt='Youtube'
                          href='https://www.youtube.com/channel/UCUupdONb4HHmL7dnne8vFKQ'
                          target='_blank'
                          rel='noreferrer'>
                          <YouTubeIcon className={`${classes.socialIconHover} ${classes.socialIconWidth}`} />
                        </a>
                      ) : null}
                      {/* </Fade> */}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DesktopMenu;
